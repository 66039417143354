import request from "@/utils/request";
//批次激活码管理

// 批次管理列表
export function findTBatchPage(parameter) {
  return request({
    url: '/api/tBatch/findTBatchPage',
    method: 'post',
    data: parameter,
  })
}

// 新增批次
export function saveTBatch(parameter) {
  return request({
    url: '/api/tBatch/saveTBatch',
    method: 'post',
    data: parameter,
  })
}

// 新增批次
export function exportCodeExcel(parameter) {
  return request({
    url: '/api/tBatch/exportExcel',
    method: 'post',
    responseType: 'blob',
    data: parameter,
  })
}

// 激活码管理列表
export function findTActivationCodePage(parameter) {
  return request({
    url: '/api/tBatch/findTActivationCodePage',
    method: 'post',
    data: parameter,
  })
}

// 导入表单
export function importPolicy(parameter) {
  return request({
    url: '/api/tBatch/importPolicy',
    method: 'post',
    data: parameter,
  })
}
