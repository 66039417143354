<template>
  <div class="account">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
          <el-breadcrumb-item :to="{path:'/batch'}">批次管理</el-breadcrumb-item>
      <el-breadcrumb-item>激活码管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- content -->
    <div class="accountMain">
      <!-- table -->
      <activationCodeTable ref="childrene" />
    </div>
  </div>
</template>
<script>
import activationCodeTable from '@/components/table/activationCodeTable.vue';

export default {
  beforeRouteLeave(to, from, next) {
    from.meta.keepAlive = false;
    next();
  },
  components: {
    activationCodeTable
  },
  data() {
    return {
      dialogFormVisible: false, //创建激活码弹窗控件
      rules: {
        title:[{ required: true,message:'必填信息' }],
        descInfo:[{ required: true,message:'必填信息' }],
        count: [{ required: true, message: '必填' }, { type:'number', message: '必须是数字' }],
        useCount: [{ required: true, message: '必填' }, { type:'number', message: '必须是数字' }],
      },
      temp: {
        title: '',
        descInfo: '',
        count: '',
        useCount: ''
      }
    };
  },

  methods: {
    //创建激活码
    modalShow() {
      this.dialogFormVisible = !this.dialogFormVisible;
      this.temp= {
        title: '',
        descInfo: '',
        count: '',
        useCount: ''
      }
    },
    //确定
    confirmCreate() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          let formData = Object.assign({},this.temp);
          saveTBatch(formData).then(() => {
            this.$refs.childrene.getData(1, 10)
            this.dialogFormVisible = false
            this.$notify({
              title: '成功',
              message: '创建成功',
              type: 'success',
              duration: 2000
            })
          })
        }
      })
    }
  }
};
</script>

<style scoped lang="stylus">
//change elemen-ui style

//面包屑样式
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner{
    font-weight: bold !important;
  }

//narmoal
.account
  overflow hidden
  .accountMain
    overflow hidden
    margin-top: 16px
    padding: 20px
    background-color: #FFFFFF
    .accTableTop
      display: flex
      justify-content: space-between
      .top-left
        display: flex
        .accCreate
          width: 116px
          height 32px
          margin-right: 24px
          background-color:#00CCCC
          cursor pointer
          color: #fff
          line-height: 32px
          text-align: center
          font-size: 14px
      .top-right
        display: flex
        .accSearch
          height: 32px
          margin-left: 24px
</style>
