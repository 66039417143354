<template>
  <div class="table">
    <el-table
      ref="multipleTable"
      :data="tableData"
      :highlight-current-row="true"
      tooltip-effect="dark"
      style="width: 100%; color: #858585"
      :header-cell-style="tableHeaderCellStyle"
    >
      <!-- <el-table-column
      @selection-change="selectRow"
     type="selection"
     fill="#00CCCC"
     fixed="left">
    </el-table-column> -->
      <!-- 表头开始 -->
      <el-table-column label="激活码" width="170" prop="cardNo" align="center">
      </el-table-column>
      <el-table-column prop="useCount" label="可使用次数" align="center">
      </el-table-column>
      <!-- <el-table-column prop="usedCount" label="已使用次数" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.usedCount || 0 }}</span>
        </template>
      </el-table-column> -->
      <el-table-column prop="state" label="使用状态" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.state | statusText }}</span>
        </template>
      </el-table-column>

      <el-table-column
        prop="createTime"
        align="center"
        label="创建时间"
        show-overflow-tooltip
      >
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="page">
      <!-- left -->
      <span class="pageLeft"
        >共 {{ count }} 条记录 第 {{ currentPage }} / {{ tatalPage }}页</span
      >
      <!-- right -->
      <el-pagination
        @size-change="SizeChange"
        background
        @current-change="CurrentChange"
        @prev-click="prevClick"
        @next-click="nextClick"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="onlyPage"
        :pager-count="5"
        layout="sizes, prev, pager, next, jumper"
        :total="count"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { findTActivationCodePage } from '@/api/batch.js';

export default {
  filters: {
    statusText(status) {
      const statusMap = {
        1: "未使用",
        2: "已使用"
      };
      return statusMap[status];
    },
  },
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      // 分页相关
      currentPage: 1, // 当前页面,默认1
      tatalPage: 0, //总页数,为加载时先为0，页面首次加载后去请求即可
      count: 0, //总的条数记录
      onlyPage: 10 // 当前页面可以展示多少条数据,默认10
    };
  },
  created() {
    //初始化获取列表数据
    this.getData(1, 10);
  },

  //监听勾选值
  watch: {
    multipleSelection: {
      handler(pre) {
        var tem = pre;
        if (tem.length == 0) {
          this.$parent.isDisable = true;
        }
        if (tem.length == 1) {
          this.$parent.isDisable = false;
          //console.log(tem);
          this.$parent.tableSelect = tem[0];
          if (tem[0].enabled == '启用') {
            this.$parent.statusV = '禁用';
          } else {
            this.$parent.statusV = '启用';
          }
        }
        if (tem.length > 1) {
          this.$parent.isDisable = true;
          this.$message({
            message: '当前仅支持下载单个数据！',
            type: 'warning'
          });
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    //表头样式回调
    tableHeaderCellStyle() {
      return 'color:#fff;background-color:#00CCCC';
    },
    // getData
    getData(currentPage, onlyPage) {
      const rLoading = this.openLoading(); // 开始loading
      findTActivationCodePage({
        currentPage: currentPage,
        pageSize: onlyPage,
        batchId: this.$route.query.id
      })
        .then((res) => {
          if (res.data.status == 200) {
            this.tableData = res.data.data.records;
            this.tatalPage = parseInt(res.data.data.size);
            this.count = parseInt(res.data.data.total);
            rLoading.close(); // 关闭loading
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((res) => {
          this.$message.error(res);
        });
    },
    // 复选框选项
    selectRow(val) {
      //console.log(this.multipleSelection);
      this.multipleSelection = val;
    },

    // 分页相关
    SizeChange(val) {
      this.onlyPage = val;
      this.getData(
        this.currentPage,
        this.onlyPage
      ); //更新数据
    },
    CurrentChange(val) {
      this.currentPage = val;
      //this.getData(this.currentPage,this.onlyPage);//更新数据
      this.getData(
        this.currentPage,
        this.onlyPage,
      );
    },
    prevClick(val) {
      this.currentPage = val;
      //this.getData(this.currentPage,this.onlyPage);//更新数据
      this.getData(
        this.currentPage,
        this.onlyPage,
      );
    },
    nextClick(val) {
      this.currentPage = val;
      //this.getData(this.currentPage,this.onlyPage);//更新数据
      this.getData(
        this.currentPage,
        this.onlyPage,
      );
    }
  }
};
</script>
<style scoped>
.table {
  margin-top: 0.1563rem;
}
.el-button {
  color: #00a3a3 !important;
}
.action {
  display: flex;
  flex-direction: column;
}
.actionP {
  cursor: pointer;
  color: #00cccc;
  margin: 0 !important;
}
.resoult {
  text-align: left;
}
.page {
  display: flex;
  margin-top: 0.3125rem;
  justify-content: space-between;
  align-items: center;
}
.pageLeft {
  color: #858585;
  font-size: 0.1094rem;
  line-height: 0.1875rem;
}
.el-pagination {
  color: #858585 !important;
  font-size: 0.1094rem !important;
  line-height: 0.1875rem !important;
}
</style>
